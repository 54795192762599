import React from 'react';
import Button from '../../atoms/Button';
import CustomImage from '../../atoms/CustomImage';
import Styled from './index.styled';
import Browser from '../../../lib/browser';

const BulletedImageCta = ({ content, image, button, anchorId }) => {
	const renderBulletList = bulletList => {
		if (!bulletList) return;

		return (
			<Styled.BulletedImageCta__List>
				{bulletList.map((item, index) => (
					<Styled.BulletedImageCta__Item key={index} as="li">
						{item}
					</Styled.BulletedImageCta__Item>
				))}
			</Styled.BulletedImageCta__List>
		);
	};

	return (
		<Styled.BulletedImageCta id={anchorId || ''}>
			<Styled.BulletedImageCta__Inner>
				<Styled.BulletedImageCta__ImageWrapper>
					<CustomImage {...image} width="343" height="245" />
				</Styled.BulletedImageCta__ImageWrapper>

				<Styled.BulletedImageCta__BlockContent>
					<Styled.BulletedImageCta__Title as="h2">
						{content.title}
					</Styled.BulletedImageCta__Title>

					{content.subTitle && (
						<Styled.BulletedImageCta__SubTitle as="p">
							{content.subTitle}
						</Styled.BulletedImageCta__SubTitle>
					)}

					<Styled.BulletedImageCta__Body>
						{content.copyList[0]}
					</Styled.BulletedImageCta__Body>

					{renderBulletList(content.bulletList)}

					<Styled.BulletedImageCta__Body>
						{content.copyList[1]}
					</Styled.BulletedImageCta__Body>
					<Button
						buttonLink={button.buttonLink || '#enquire'}
						buttonText={button.text || button.buttonText}
						buttonCategory={button.dataGACategory || button.buttonCategory}
						buttonAction={button.dataGAAction || button.buttonAction}
						buttonLabel={button.dataGALabel || button.buttonAction}
						buttonType="primary"
						iconName={button.iconName || 'chat'}
						callback={event =>
							Browser.handleClick(event, button.buttonLink || '#enquire')
						}
						iconPosition={button.iconPosition}
					/>
				</Styled.BulletedImageCta__BlockContent>
			</Styled.BulletedImageCta__Inner>
		</Styled.BulletedImageCta>
	);
};

export default BulletedImageCta;
