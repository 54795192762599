import styled, { css } from 'styled-components';
import { BulletList } from '../../atoms/List/index.styled';
import { Grid } from '../../../styles/generic/container';
import breakpoints from '../../../styles/settings/breakpoints';
import { gap, spacing, margins } from '../../../styles/settings/spacing';
import { above, below } from '../../../styles/tools/media';
import aspectRatio from '../../../styles/tools/aspectRatio';
import {
	smBodyLight,
	mdPlusBodyRegular,
	xlTitleRegular,
} from '../../atoms/Typography/index.styled';

const BulletedImageCta = styled.section`
	position: relative;
	margin-bottom: ${spacing[48]};
	background-color: var(--secondaryBackground);
	z-index: 1;

	${above(
		breakpoints[960],
		() => css`
			margin-bottom: ${spacing[64]};
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			margin-bottom: ${spacing[80]};
		`
	)}
`;

const BulletedImageCta__Inner = styled(Grid)`
	padding-top: ${spacing[48]};
	padding-bottom: ${spacing[48]};

	${below(
		breakpoints[960],
		() => css`
			overflow: hidden;
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			align-items: center;
			padding-top: ${spacing[64]};
			padding-bottom: ${spacing[64]};
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			padding-top: ${spacing[80]};
			padding-bottom: ${spacing[80]};
		`
	)}
`;

const BulletedImageCta__ImageWrapper = styled.div`
	grid-column: 1 / -1;
	margin-bottom: ${spacing[48]};

	& > picture {
		padding-top: ${aspectRatio('3:2')};
		box-shadow: ${gap[16]} ${gap[16]} 0 0 var(--primaryAccent);
	}

	${below(
		breakpoints[960],
		() => css`
			width: calc(100vw - ${margins[16]});
		`
	)}

	${above(
		breakpoints[600],
		() => css`
			margin-bottom: ${spacing[64]};
		`
	)}

	${above(
		breakpoints[960],
		() => css`
			grid-column: 8 / -1;
			margin-bottom: ${gap[32]};
			order: 2;

			& > picture {
				padding-top: ${aspectRatio('1:1')};
				box-shadow: ${gap[24]} ${gap[24]} 0 0 var(--primaryAccent);
			}
		`
	)}

	${above(
		breakpoints[1440],
		() => css`
			& > picture {
				box-shadow: ${gap[32]} ${gap[32]} 0 0 var(--primaryAccent);
			}
		`
	)}
`;

const BulletedImageCta__BlockContent = styled.div`
	grid-column: 1 / -1;

	${above(
		breakpoints[960],
		() => css`
			grid-column: 1 / span 6;
		`
	)}
`;

const BulletedImageCta__Title = styled(xlTitleRegular)`
	margin-bottom: ${gap[16]};
	color: var(--primaryForeground);

	${above(
		breakpoints[960],
		() => css`
			margin-bottom: ${gap[24]};
		`
	)}
`;

const BulletedImageCta__SubTitle = styled(mdPlusBodyRegular)`
	margin-bottom: ${gap[16]};

	${above(
		breakpoints[960],
		() => css`
			margin-bottom: ${gap[40]};
		`
	)}
`;

const BulletedImageCta__List = styled(BulletList)`
	margin-bottom: ${gap[24]};

	${above(
		breakpoints[960],
		() => css`
			margin-bottom: ${gap[32]};
		`
	)}
`;

const BulletedImageCta__Item = styled(smBodyLight)`
	margin-bottom: ${gap[24]};

	&:last-child {
		margin-bottom: 0;
	}
`;

const BulletedImageCta__Body = styled(smBodyLight)`
	margin-bottom: ${gap[24]};

	&:last-child {
		margin-bottom: ${gap[0]};
	}

	${above(
		breakpoints[960],
		() => css`
			margin-bottom: ${gap[32]};
		`
	)}
`;

export default {
	BulletedImageCta,
	BulletedImageCta__Inner,
	BulletedImageCta__ImageWrapper,
	BulletedImageCta__BlockContent,
	BulletedImageCta__Title,
	BulletedImageCta__SubTitle,
	BulletedImageCta__List,
	BulletedImageCta__Item,
	BulletedImageCta__Body,
};
